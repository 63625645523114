@import "./../../app/styles/mixins";

#outer-container {
  position: relative;
  overflow-x: hidden;
}

#page-wrap {
  transition: transform 0.3s ease;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 20px;

  display: none;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-menu {
  background: var(--burger-menu-bg);
  padding: 156px 60px 42px 45px;

  @include mobile-only {
    padding: 156px 27px 0 37px;
  }
}

.menu-item {
  color: var(--text-primary);
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-size: 42px;
  font-weight: 500;
  display: block;
  margin-bottom: 24px;
  cursor: pointer !important;

  @include tablet {
    font-size: 36px;
  }

  @include mobile-only {
    font-size: 32px;
    letter-spacing: -0.7px;
  }

  &:active {
    color: var(--text-primary);
  }
  &:hover {
    color: var(--text-primary) !important;
  }
}

.menu-list {
  display: flex;
  flex-direction: column;
}

.cross-button {
  top: 24px !important;
  right: 24px !important;
}

.menu-email {
  margin-top: auto;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #111827;
  opacity: 0.5;
}

.menu-item:hover {
  color: #ffffff;
}