@mixin desktop-large {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin tablet-desktop {
  @media (min-width: 744px) and (max-width: 1439px) {
    @content;
  }
}

@mixin mobile-only {
  @media (max-width: 743px) {
    @content;
  }
}

@mixin small-desktop {
  @media (min-width: 1180px) and (max-width: 1260px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1261px) and (max-width: 1439px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 744px) and (max-width: 1179px) {
    @content;
  }
}