@import "../../../../app/styles/mixins";
@import "../../../../app/styles/vars";

.featureItem {
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  @include tablet-desktop {
    flex-direction: row;
    column-gap: 32px;
    align-items: flex-start;
  }

  & .featureIconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: var(--color-background-third);

    @include tablet-desktop {
      flex-shrink: 0;
    }
  }

  & .featureTextWrapper {
    & .featureTitle {
      color: var(--text-primary);
      font-feature-settings: 'liga' off;
      font-family: Poppins, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }

    & .featureDescription {
      margin-top: 8px;
      color: var(--text-third);
      font-feature-settings: 'liga' off;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
}