:root {
  --white: #ffffff;
  --dark: #222328;
  --dark2: #212328;;
  --white50Op: #FFFFFF80;
  --dark50Op: #1A1C2280;
  --dark-secondary: #212328;
  --dark-blue-grey: #111827;
  --dark-blue-grey2: #2a2d3f;
  --dark-blue-grey50Op: #1118278C;
  --bright-yellow: #FDCC1B;
  --cool-gray-blue: #677186;
  --cool-gray-blue70_Op: #677186B3;
  --dark-brown-black: #120A00;
  --light-gray-blue: #F2F5F7;
  --bright-blue: #578EFB;
  --pale-gray-blue: #AEB7CA;
  --pale-gray-blue70_Op: #AEB7CAB3;
  --very-light-gray: #F7F7F8;
  --dark-gray: #3A3D43;
  --light-gray: #D1D5DB;
  --light-gray250_Op: #D9DBE180;
  --light-gray3: #D9D9D9;
  --very-light-blue-gray: #ECEEF3;
  --charcoal-gray: #3A3D43;
  --slate-gray: #4D5158;
  --light-dark-blue: #0e1a2b;
  --bright-yellow75_Op: #FDCC1BBF;
  --transparent: transparent;
  --white20_Op: #ffffff33;
  --gray-white70_Op: #A8B5CEB3;
  --white-gray: #6A747E;
  --true-dark: #131417;
}

html  {
  --color-background: var(--white);
  --color-background2: var(--white);
  --color-background-secondary: var(--white);
  --color-background-third: var(--very-light-gray);
  --text-primary: var(--dark-blue-grey);
  --text-secondary: var(--cool-gray-blue);
  --text-third: var(--cool-gray-blue70_Op);
  --text-four: var(--dark-brown-black);
  --text-five: var(--cool-gray-blue);
  --text-six: var(--cool-gray-blue);
  --color-background-outer-box: var(--white50Op);
  --hero-background-desktop: url("../../assets/images/top-bg-light.webp");
  --hero-background-tablet: url("../../assets/images/top-bg-light-tablet.webp");
  --hero-background-mobile: url("../../assets/images/top-bg-light-mobile.webp");
  --link-hover: var(--dark-blue-grey2);
  --link-active: var(--light-dark-blue);
  --thumb-outer: var(--very-light-blue-gray);
  --thumb-inner: var(--light-gray);
  --input-background: var(--white);
  --input-border-color: var(--light-gray3);
  --input-placeholder-color: var(--dark-blue-grey50Op);
  --burger-menu-bg: var(--white);
}

html.dark {
  --color-background: var(--dark);
  --color-background2: var(--dark2);
  --text-primary: var(--white);
  --text-secondary: var(--pale-gray-blue);
  --text-third: var(--pale-gray-blue70_Op);
  --text-four: var(--white);
  --text-five: var(--gray-white70_Op);
  --text-six: var(--white-gray);
  --color-background-secondary: var(--dark-secondary);
  --color-background-outer-box: var(--dark50Op);
  --color-background-third: var(--dark-gray);
  --hero-background-desktop: url("../../assets/images/top-bg-dark.webp");
  --hero-background-tablet: url("../../assets/images/top-bg-dark-tablet.webp");
  --hero-background-mobile: url("../../assets/images/top-bg-dark-mobile.webp");
  --link-hover: var(--bright-yellow);
  --link-active: var(--bright-yellow75_Op);
  --thumb-outer: var(--charcoal-gray);
  --thumb-inner: var(--slate-gray);
  --input-background: var(--white20_Op);
  --input-border-color: var(--transparent);
  --input-placeholder-color: var(--light-gray250_Op);
  --burger-menu-bg: var(--true-dark);
}

body {
  background-color: var(--color-background);
}