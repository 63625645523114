@import "../../app/styles/vars";
@import "../../app/styles/mixins";

.footerWrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  @include desktop-large {
    margin-inline: -120px;
    padding-inline: 120px;
    background-image: url("../../assets/images/layout-bottom-left-min.png");
    background-repeat: no-repeat;
    background-size: 400px 400px;
    background-position: -230px 60px;
  }

  & .button {
    position: absolute;
    right: 3px;
    top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:  var(--dark-blue-grey);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 90px;
    height: 36px;
  }

  & .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;

    @include mobile-only {
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
      row-gap: 0;
    }

    & .inputWrapper {
      position: relative;
      margin-top: 12px;

      & .input {
        width: 300px;
        height: 44px;
        padding: 0 106px 0 12px;
        border-radius: 8px;
        background-color: var(--input-background);
        border: 1px solid var(--input-border-color);

        color: var(--text-primary);
        font-feature-settings: 'liga' off;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        caret-color: var(--bright-yellow);

        &::placeholder {
          color: var(--input-placeholder-color);
          font-feature-settings: 'liga' off;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          opacity: 0.5;
        }

        &:focus {
          outline: none;
        }
      }
    }

    & .title {
      color: var(--text-primary);
      font-feature-settings: 'liga' off;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      @include mobile-only {
        margin-top: 32px;
      }
    }
  }

  & .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    row-gap: 16px;

    .unscrapableText {
      user-select: none; /* Prevents selection on most modern browsers */
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
    }

    & .text {
      color: var(--text-primary);
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      opacity: 0.5;
      text-decoration: none;
    }

    & .row {
      display: flex;
      align-items: center;
      column-gap: 10px;

      & .round {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        opacity: 0.5;
        background-color: var(--text-primary);
      }
    }
  }
}

.modalContent {
  display: flex;
  align-items: center;
  flex-direction: column;

  & .title {
    color: var(--text-primary);
    font-feature-settings: 'liga' off;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  & .subTitle {
    color: var(--text-third);
    font-feature-settings: 'liga' off;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    margin-top: 16px;
  }

  & .modalButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 90px;
    height: 36px;
    margin-top: 16px;

    & .buttonText {
      color:  var(--dark-blue-grey);
    }
  }
}

