@import '../../app/styles/vars';
@import '../../app/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  padding: 124px 0;

  @include tablet-desktop {
    padding: 200px 32px 100px;
    row-gap: 80px;
  }

  @include mobile-only {
    padding: 100px 0 100px;
    row-gap: 80px;
  }

  & .button {
    padding: 12px 26px;

    @include mobile-only {
      width: 100%;
    }

    & .buttonText {
      color: $dark-blue-grey;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      text-transform: uppercase;

      @include mobile-only {
        margin: 0 auto;
      }
    }
  }

  & .headline {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .title {
      color: var(--text-primary);
      font-family: Poppins, sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;

      @include tablet-desktop {
        font-size: 32px;
        letter-spacing: -0.16px;
      }

      @include mobile-only {
        font-size: 28px;
        letter-spacing: -0.14px;
      }
    }
  }

  & .featuresList {
    display: flex;
    column-gap: 42px;

    @include tablet-desktop {
      flex-direction: column;
      row-gap: 80px;
    }

    @include mobile-only {
      flex-direction: column;
      row-gap: 60px;
    }
  }
}