@import "../../app/styles/vars";
@import "../../app/styles/mixins";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;

  @include mobile-only {
    column-gap: 12px;
  }

  & .burgerButton {
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 32px;
  height: 62px;
  border-radius: 16px;
  background-color: var(--color-background2);
  flex-grow: 1;
  transition: 0.3s;

  @include mobile-only {
    padding: 0 16px 0 16px;
  }

  & .headerLeft {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  & .nav {
    width: 560px;

    & .navList {
      display: flex;
      column-gap: 28px;

      & .navListItemName {
        color: var(--text-primary);
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        transition: .3s ease;
        cursor: pointer !important;
        text-decoration: none;

        &:hover {
          color: var(--link-hover);
        }

        &:active {
          color: var(--link-active);
        }
      }
    }
  }

  & .buttonsContainer {
    display: flex;
    column-gap: 36px;
    align-items: center;

    & .iconsContainer {
      display: flex;
      column-gap: 20px;
      align-items: center;

      & .iconButton {
        display: flex;
        align-items: center;
        width: 16px;
        height: 16px;

        & > svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    & .downloadButton {
      display: flex;
      padding: 10px 40px;
      justify-content: center;
      align-items: center;

      & .downloadButtonText {
        color: $dark-blue-grey;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
      }
    }
  }
}