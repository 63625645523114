.appButton {
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  --button-background: #1890ff;
  --button-hover-background: #40a9ff;
  --button-active-background: #096dd9;
  --box-shadow-color: rgba(24, 144, 255, 0.8);

  &.TEXT {
    background-color: transparent;
    color: #333;
  }

  &.PRIMARY {
    color: white;
    background-color: var(--button-background);

    &:hover {
      background-color: var(--button-hover-background);
    }

    &:active {
      background-color: var(--button-active-background);
    }
  }

  &.PRIMARY.clicked {
    box-shadow: 0 0 0 4px var(--box-shadow-color);
    transition: box-shadow 0.1s ease;
  }
}