@import "../../app/styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  padding-inline: 120px;
  padding-top: 42px;
  background-image: var(--hero-background-desktop);
  background-size: 100% 1145px;
  background-repeat: no-repeat;
  overflow: hidden;

  @include tablet-desktop {
    padding-inline: 32px;
    background-image: var(--hero-background-tablet);
    background-size: 100% 1378px;
  }

  @include mobile-only {
    padding-inline: 20px;
    background-image: var(--hero-background-mobile);
    overflow: hidden;
    background-size: 100% 1378px;
  }
}