@keyframes glow {
  0% {
    filter: brightness(100%);
    transform: scale(1.2);
  }
  50% {
    filter: brightness(120%);
    transform: scale(1.3);
  }
  100% {
    filter: brightness(100%);
    transform: scale(1.2);
  }
}

.container {
  width: 549px;
  height: 600px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  animation: glow 5s infinite ease-in-out;
}