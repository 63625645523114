@import '../../app/styles/vars';
@import '../../app/styles/mixins';

@keyframes pointAnimation {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 0 0 5px 5px #FDCC1B33;
  }
  100% {
    box-shadow: none;
  }
}

.progressContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translate(-48%);
  width: 34px;
  min-height: 100%;

  @include mobile-only {
    left: 16px;
  }

  & .track {
    width: 2px;
    min-height: 100%;
    background-color: #D1D5DB;
  }

  & .trackActive {
    position: fixed;
    width: 2px;
    background-color: #FDCC1B;
    transition: height 0.1s ease;
    height: 0;
    z-index: 11;
  }

  & .trackEnding {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 4px;
    height: 382px;
    bottom: 0;
    background: var(--color-background);

    & .trackEndingInner {
      min-height: 100%;
      width: 2px;
      background: linear-gradient(180deg, #D1D5DB 0%, rgba(209, 213, 219, 0.00) 100%);
    }
  }

  & .thumbWrapper {
    position: absolute;
    background-color: var(--color-background);
    z-index: 20;
  }

  & .thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    background-color: var(--thumb-outer);
    border-radius: 50%;
    transition: 0.3s ease;

    & .thumbInnerCircle {
      width: 20px;
      height: 20px;
      background-color: var(--thumb-inner);
      border-radius: 50%;
    }

    & .thumbInnerActive {
      background-color: #FDCC1B;
    }
  }

  & .thumbActive {
    background-color: #FDCC1B33;
    animation: pointAnimation 1.4s ease-in-out;
  }
}