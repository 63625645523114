@import "../../app/styles/vars";
@import "../../app/styles/mixins";

.container {
  padding: 124px 0 86px;

  @include tablet-desktop {
    overflow: hidden;
  }

  @include tablet-desktop {
    margin-inline: -32px;
  }

  & .title {
    text-align: center;
    color: var(--text-four);
    font-family: Poppins, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;

    @include tablet-desktop {
      font-size: 32px;
      letter-spacing: -0.16px;
    }

    @include mobile-only {
      font-size: 32px;
      letter-spacing: -0.16px;
      text-align: start;
    }
  }

  & .subTitle {
    width: 538px;
    margin: 24px auto 0;
    color: var(--text-five);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    @include tablet-desktop {
      font-size: 16px;
    }

    @include mobile-only {
      margin: 24px 0 0;
      text-align: start;
      width: 90%;
    }
  }

  & .appDemonstrationTitle {
    color: var(--text-primary);
    font-feature-settings: 'liga' off;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  & .titleWrapper {
    display: flex;
    align-items: center;
  }

  & .beta {
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 58px;
    background-color: var(--bright-yellow);
    border-radius: 8px;
    color: var(--dark-blue-grey);
    font-size: 14px;
    text-align: center;
    font-family: Inter, sans-serif;
    font-weight: 600;
    flex-shrink: 0;
  }

  & .betaMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 58px;
    background-color: var(--bright-yellow);
    border-radius: 8px;
    color: var(--dark-blue-grey);
    font-size: 14px;
    text-align: center;
    font-family: Inter, sans-serif;
    font-weight: 600;
  }

  & .appDemonstrationSubTitle {
    color: var(--text-six);
    font-feature-settings: 'liga' off;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  & .appDemonstrationContainerMobile {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    position: relative;
    padding-left: 54px;

    & .appDemonstrationSectionMobile {
      position: relative;
      padding: 45px 0;
    }

    & .appAppDemonstrationSubTitleMobile {
      margin-top: 22px;
      width: 95%;
    }
  }

  & .appDemonstrationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    position: relative;

    @include mobile-only {
      padding-left: 54px;
    }

    & .appDemonstrationSection {
      position: relative;
      display: flex;
      width: 854px;
      column-gap: 34px;
      padding-bottom: 66px;

      @include tablet-desktop {
        width: 100%;
      }

      & .subTitleRight {
        width: 250px;
        text-align: end;

        @include tablet-desktop {
          width: 80%;
        }
      }

      & .subTitleLeft {
        width: 90%;

        @include tablet-desktop {
          width: 100%;
        }
      }

      & .innerLeftSection {
        width: 100%;
        padding-right: 60px;

        @include tablet-desktop {
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          padding-right: 24px;
        }

        & .textBlock {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          row-gap: 22px;
          width: 350px;
          padding-top: 82px;
          flex-shrink: 0;
        }
      }

      & .innerRightSection {
        width: 100%;
        padding-left: 60px;

        @include tablet-desktop {
          display: flex;
          align-items: flex-start;
          padding-left: 24px;
        }

        & .textBlock {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          row-gap: 22px;
          width: 350px;
          padding-top: 82px;
          flex-shrink: 0;

          @include tablet-desktop {
            width: 85%;
          }
        }
      }
    }
  }
}

.appStoreButton {
  display: inline-block;
  margin: 0 4px;

  & .appStoreText {
    color: $bright-blue;
    font-feature-settings: 'liga' off;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}

.startButton {
  padding: 12px 28px;

  @include mobile-only {
    width: 100%;
    margin-top: 50px;
  }

  & .buttonText {
    color: $dark-blue-grey;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;

    @include mobile-only {
      margin: 0 auto;
    }
  }
}

.startButtonDesktopContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;


  & .startButtonDesktop {
    padding: 12px 28px;
    width: 300px;
    display: flex;
    justify-content: center;

    & .buttonText {
      color: $dark-blue-grey;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      text-transform: uppercase;

      @include mobile-only {
        margin: 0 auto;
      }
    }
  }
}



.image {
  padding-top: 80px;
  width: 350px;
  height: 350px;

  @include mobile-only {
    width: 275px;
    height: 275px;
    padding-top: 24px;
  }
}

.textRight {
  text-align: right;

  @include tablet {
    max-width: 80%;
  }
}

.thumbWrapper {
  position: absolute;
  background-color: var(--color-background);
  z-index: 20;
  right: 50%;
  transform: translate(50%);
  top: 75px
}

.thumbWrapperMobile {
  position: absolute;
  background-color: var(--color-background);
  z-index: 20;
  top: 40px;
  left: -55px;
}

.thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  background-color: var(--thumb-outer);
  border-radius: 50%;
  transition: 0.3s ease;

  & .thumbInnerCircle {
    width: 20px;
    height: 20px;
    background-color: var(--thumb-inner);
    border-radius: 50%;
  }

  & .thumbInnerActive {
    background-color: #FDCC1B;
  }
}

.thumbActive {
  background-color: #FDCC1B33;
  animation: pointAnimation 1.4s ease-in-out;
}

@keyframes pointAnimation {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 0 0 5px 5px #FDCC1B33;
  }
  100% {
    box-shadow: none;
  }
}

.progressContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translate(-48%);
  width: 34px;
  min-height: 100%;

  @include mobile-only {
    left: 16px;
  }

  & .track {
    width: 2px;
    min-height: 100%;
    background-color: #D1D5DB;
  }

  & .trackActive {
    position: fixed;
    width: 2px;
    background-color: #FDCC1B;
    transition: height 0.1s ease;
    height: 0;
    z-index: 11;
  }

  & .trackEnding {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 4px;
    height: 382px;
    bottom: 0;
    background: var(--color-background);

    & .trackEndingInner {
      min-height: 100%;
      width: 2px;
      background: linear-gradient(180deg, #D1D5DB 0%, rgba(209, 213, 219, 0.00) 100%);
    }
  }
}