@import "../../app/styles/vars";
@import "../../app/styles/mixins";

.container {
  display: flex;
  margin-top: 65px;
  justify-content: space-between;
  align-items: flex-start;

  @include desktop {
    column-gap: 10%;
  }

  @include small-desktop {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 56px;
    padding-bottom: 49px;
  }

  @include tablet {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 56px;
    padding-bottom: 49px;
  }

  @include mobile-only {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 56px;
  }

  & .imageSection {
    @include desktop {
      padding-right: 60px;
    }

    @include desktop-large {
      padding-right: 60px;
    }
  }

  & .boxOuter {
    width: 717px;
    border-radius: 24px;
    padding-bottom: 14px;
    margin-top: 73px;
    border: 20px solid var(--color-background-outer-box);
    margin-left: 45px;
    padding-top: 13px;

    @include desktop {
      width: calc(100%);
      height: 100%;
      margin-top: 73px;
      margin-left: 60px;
      padding-bottom: 14px;
    }

    @include small-desktop {
      width: calc(100%);
      height: 100%;
      margin-top: 32px;
      margin-left: 60px;
      padding-bottom: 14px;
    }

    @include tablet {
      width: calc(100%);
      height: 100%;
      margin-top: 32px;
      margin-left: 60px;
      padding-bottom: 14px;
    }

    @include mobile-only {
      width: calc(100%);
      height: 100%;
      margin-top: 21px;
      margin-left: 0;
      margin-right: -60px;
      padding-top: 7px;
      padding-bottom: 8px;
    }

    & .textSection {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 725px;
      padding: 54px;
      border-radius: 24px;
      margin-left: -65px;
      background-color: var(--color-background-secondary);

      @include tablet-desktop {
        width: calc(100% + 16px);
        align-self: flex-start;
        margin-left: -40px;
      }

      @include mobile-only {
        width: calc(100% + 40px);
        padding: 32px;
        margin-left: -50px;
      }

      & .title {
        color: var(--text-primary);
        font-family: Poppins, sans-serif;
        font-size: 52px;
        font-style: normal;
        font-weight: 600;
        line-height: 72px;
        letter-spacing: -0.26px;
        font-feature-settings: 'liga' off;

        @include tablet-desktop {
          font-size: 38px;
          line-height: 54px;
          letter-spacing: -0.19px;
        }

        @include mobile-only {
          font-size: 22px;
          line-height: 32px;
          letter-spacing: -0.14px;
        }
      }

      & .paragraph {
        margin-top: 16px;
        color: var(--text-secondary);
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        font-feature-settings: 'liga' off;

        @include mobile-only {
          font-size: 15px;
          line-height: 22px;
        }
      }

      & .iconButton {
        margin-top: 32px;
      }
    }
  }

  & .image {
    width: 338px;
    height: 721px;

    @include tablet-desktop {
      width: 304px;
      height: 650px;
    }

    @include mobile-only {
      width: 262px;
      height: 558px;
    }
  }
}