@import './../../app/styles/mixins';

.container {
  background-color: white;
  border-radius: 16px;
  margin-top: 18px;
  margin-bottom: 18px;
  min-height: 100vh;
  position: relative;

  & .spinnerWrapper {
    position: absolute;
    left: 50%;
    top: 260px;
    transform: translate(-50%);
  }

  @include desktop-large {
    margin-inline: 10%;
  }
}